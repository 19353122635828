<template>
  <div class="nofound-box">
    <div class="img">
      <img class="nofound-pic" src="../assets/image/404.png"/>
    </div>
    <div style="padding: 30px 0;text-align: center;">
      <div class="btn-search" @click.prevent="submit">返回首页</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoFind',
  methods: {
    submit () {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.nofound-box {
  margin: 100px auto 0 auto;
}
.img {
  width: 500px;
  height: 351px;
  margin: auto;
}
.img img{
  display: block;
  height: 100%;
  width: 100%;
}
</style>
